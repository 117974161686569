import React, { Fragment, useState } from "react";
// Styles ...
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import Logotec from "../../../assets/images/tecnomodulare_logo_2.svg";
// Components ...
import Error from "../../../components/Error";
// Hooks ...
import { CREATE_FORGOT_PASSWORD } from "../../../hooks/User";
import { useMutation } from "@apollo/client";

const ForgotPasswordBoxed = () => {
  // State for handling email input
  const [email, setEmail] = useState("");

  // State for handling messages
  const [emailSend, setEmailSend] = useState(false);

  // State for handling errors
  const [error, setError] = useState("");

  // Close messages
  const closeMessages = () => {
    setEmailSend(false);
  };

  // Mutation to create forgot password
  const [forgotPassword] = useMutation(CREATE_FORGOT_PASSWORD, {
    variables: {
      email: email,
    },
    onError(error) {
      setError(error);
    },
    update(cache, result) {
      //Reset variables in form and hooks...
      setEmail("");
      document.getElementById("email").value = "";
      setEmailSend(true);
    },
  });

  // Handle change in the email input
  const handleInput = (e) => {
    setEmail(e.target.value);
  };

  // Validate if the form is ready to be submitted
  const isFormValid = () => {
    return email.length > 0;
  };
  return (
    <Fragment>
      <div className="h-100 bg-layout bg-animation">
        <div className="d-flex flex-col h-100 justify-content-center align-items-center">
          <img
            className="logotec"
            src={Logotec}
            alt="tecnomodulares logo cube"
          />
          <Col md="8" className="mx-auto app-login-box">
            <div className="modal-dialog w-100 mx-auto">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="h5 modal-title">
                    ¿Olvidaste tu contraseña?
                    <h6 className="mt-1 mb-0 opacity-8">
                      <span>
                        Utilice el siguiente formulario para recuperarlo.
                      </span>
                    </h6>
                  </div>
                </div>
                <div className="modal-body">
                  <div>
                    <Form>
                      <Row>
                        {error && <Error error={error} />}
                        <FormGroup>
                          {emailSend && (
                            <div
                              className="alert alert-success alert-dismissible fade show"
                              role="alert"
                            >
                              Correo enviado con éxito, revise su bandeja de
                              entrada.
                              <button
                                className="btn-close"
                                aria-label="Close"
                                onClick={closeMessages}
                              />
                            </div>
                          )}
                        </FormGroup>

                        <Col md={12}>
                          <FormGroup>
                            <Label for="exampleEmail">Email</Label>
                            <Input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Ingresar Email"
                              onChange={handleInput}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                  <div className="divider" />
                  <h6 className="mb-0">
                    <a href="#/admin" className="text-primary">
                      Iniciar sesión en cuenta existente
                    </a>
                  </h6>
                </div>
                <div className="modal-footer clearfix">
                  <div className="float-end">
                    <Button
                      color="primary"
                      size="lg"
                      onClick={forgotPassword}
                      disabled={!isFormValid()}
                    >
                      Recuperar contraseña
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </div>
    </Fragment>
  );
};

export default ForgotPasswordBoxed;
