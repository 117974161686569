import React, { useState } from "react";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import { SIGNIN } from "../../../hooks/User";
import { useMutation } from "@apollo/client";

import Error from "../../../components/Error";

import "../../../assets/css/admin_login.css";
import Logotec from "../../../assets/images/tecnomodulare_logo_2.svg";


const LoginBoxed = ({ props, match }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [signin, { data, loading, error }] = useMutation(SIGNIN, {
    variables: {
      email: email,
      password: password,
    },
    update(cache, result) {
      localStorage.setItem("auth-token", result.data.signin.token);
      window.location.reload();
    },
  });

  // Update values from LogIn form
  const handleInput = (e) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    if (name === 'email') setEmail(value);
    if (name === 'password') setPassword(value);
  };

  const isFormValid = (e) => {
    return (
      email.length > 0 &&
      password.length > 0
    );
  };

  return (
    <div className="h-100 bg-layout  bg-animation">
      <div className="d-flex flex-col h-100 justify-content-center align-items-center">
        <img className="logotec" src={Logotec} alt="tecnomodulares logo cube" />
        <Col md="8" className="mx-auto app-login-box">
          <div className="modal-dialog w-100 mx-auto">
            <div className="modal-content">
              <div className="modal-body">
                <div className="h5 modal-title text-center">
                  <h4 className="mt-2">
                    <div>Bienvenido</div>
                    <span>Inicie sesión en su cuenta para continuar.</span>
                  </h4>
                </div>
                <Form>
                  <Error error={error} />
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Input
                          className="inputAdmin"
                          type="email"
                          name="email"
                          id="exampleEmail"
                          onChange={handleInput}
                          placeholder="Ingresar Email" />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Input
                          className="inputAdmin"
                          type="password"
                          name="password"
                          id="examplePassword"
                          onChange={handleInput}
                          placeholder="Ingresar Contraseña" />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="modal-footer clearfix">
                <div className="float-start">
                  <a href="#/forgot-password-boxed" className="btn-lg btn btn-link">
                    Recuperar Contraseña
                  </a>
                </div>
                <div className="float-end">
                  <Button
                    type="submit"
                    className="primary"
                    size="lg"
                    onClick={signin}
                    disabled={!isFormValid()}>
                    Iniciar sesión
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </div>
    </div>
  );
};

export default LoginBoxed;
