import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./DemoPages/Main";
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";

import { ApolloProvider} from '@apollo/client';
import client from "./client";

const store = configureStore();
const rootElement = document.getElementById("root");


ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <HashRouter>
        <Main />
      </HashRouter>
    </Provider>
  </ApolloProvider>,
  rootElement
);



