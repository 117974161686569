import logo from '../../assets/images/logo.svg'
import imgi_1 from '../../assets/images/imgi_1.svg'
import imgi_2 from '../../assets/images/imgi_2.svg'
import imgi_3 from '../../assets/images/imgi_3.svg'
import circleimg1 from '../../assets/images/circleimg1.png'
import circleimg2 from '../../assets/images/circleimg2.png'
import circleimg3 from '../../assets/images/circleimg3.png'
import circleimg4 from '../../assets/images/circleimg4.png'
import circleimg5 from '../../assets/images/circleimg5.png'
import logo_w from '../../assets/images/logo_w.svg'
import * as FaIcons from "react-icons/fa"
import '../../assets/css/home.css';
import '../../assets/css/normalize.css'

const MainPage = () => {
    return (
        <div className='body'>

            <div className="containerLanding">
                <header>
                    <div className="logo">
                        <img src={logo} alt="" srcSet="tecnomodulare wood + tech" />
                    </div>
                    <div className="header-social">
                        <a href="https://www.tiktok.com/@tecnomodulare" target="_blank"><FaIcons.FaTiktok className="text-4xl text-[#3F4D68] w-full" /></a>
                        <a><FaIcons.FaFacebookSquare className="text-4xl text-iconos w-full" /></a>
                        <a href="https://twitter.com/tecnomodulare" target="_blank"><FaIcons.FaTwitter className="text-4xl text-iconos w-full"/></a>
                        <a href="https://instagram.com/tecnomodulare?igshid=NDBlY2NjN2I=" target="_blank"><FaIcons.FaInstagram className="text-4xl text-iconos w-full"/></a>
                    </div>
                </header>
                <section id="section1">
                    <div className="section1-circle_text">
                        <p>
                            SOMOS UNA EMPRESA DE
                            <br />
                            <span>
                                TECNOLOGÍA
                            </span>
                            <br />
                            DEDICADA A LA FABRICACIÓN DE:
                        </p>
                        <hr />
                        <h2>
                            MUEBLES DE <br />
                            MADERA
                        </h2>
                        <p>
                            Creemos que el proceso de fabricación de cualquier
                            mueble de madera debe ser lo más eficiente posible
                            y el mueble debe tener la más alta calidad
                        </p>
                    </div>
                </section>
                <section id="section2">
                    <div className="section2-item">
                        <img src={imgi_1} alt="img" />
                        <p>
                            Fabricamos: cocinas, closets, lavanetas,
                            puertas, lambrines, y cualquier mueble
                            sobre diseño ya sea residencial, de oficina
                            o comercial.
                        </p>
                    </div>
                    <div className="section2-item">
                        <img src={imgi_2} alt="img" />
                        <p>
                            Desarrollamos software de tecnologia propia
                            para automatizar todo el proceso de
                            fabricación de muebles, desde la cotización,
                            compra de materia prima, y todo el proceso
                            de fabricación, hasta la entrega de los
                            muebles terminados.
                        </p>
                    </div>
                    <div className="section2-item">
                        <img className="img-max" src={imgi_3} alt="img" />
                        <p>
                            La entrega del producto terminado la hacemos en muy pocos días (máximo 30
                            días), el cliente puede monitorear o bien
                            recibir notificaciones en todo momento con
                            nuestra aplicación, sobre el estatus de su proyecto.
                        </p>
                    </div>
                </section>
                <section id="section3">
                    <div className="section3-item"><img src={circleimg1} alt="img" /></div>
                    <div className="section3-item"><img src={circleimg2} alt="img" /></div>
                    <div className="section3-item"><img src={circleimg3} alt="img" /></div>
                    <div className="section3-item"><img src={circleimg4} alt="img" /></div>
                    <div className="section3-item"><img src={circleimg5} alt="img" /></div>
                </section>
                <section id="section4">
                    <div className="section4-text1">
                        <hr />
                        <p>
                            En nuestro proceso de fabricación usamos solamente maquinaria de tecnología de última
                            generación, con la cual aparte de alcanzar la más alta calidad en cortes, enchapados y
                            ensambles, eliminamos el error humano en la fabricación y recortamos el tiempo de fabricación
                            considerablemente
                        </p>
                        <hr />
                    </div>
                    <div className="section4-text2">
                        <h3>Condiciones</h3>
                        <div className="section4-text2-item">
                            <span>48</span>
                            <p>
                                HORAS PARA ENTREGA <br />
                                DE COTIZACIÓN
                            </p>
                        </div>
                        <div className="section4-text2-item">
                            <span>30</span>
                            <p>
                                DIAS MÁXIMOS PARA LA <br />
                                ENTREGA DE SU PRODUCTO
                            </p>
                        </div>
                    </div>
                </section>
            </div>
            <footer>
                <div className="footer-logo">
                    <img src={logo_w} alt="" srcSet="tecnomodulare wood + tech" />
                </div>
                <div className="footer-social">
                    <a href="https://www.tiktok.com/@tecnomodulare" target="_blank"><FaIcons.FaTiktok className="text-4xl text-white w-full " /></a>
                    <a><FaIcons.FaFacebookSquare className="text-4xl text-white w-full " /></a>
                    <a href="https://twitter.com/tecnomodulare" target="_blank"><FaIcons.FaTwitter className="text-4xl text-white w-full "/></a>
                    <a href="https://instagram.com/tecnomodulare?igshid=NDBlY2NjN2I=" target="_blank"><FaIcons.FaInstagram className="text-4xl text-white w-full "/></a>
                </div>
                <div className="footer-ul">
                    <ul>
                        <li><a>Crecimiento #104</a></li>
                        <li><a>Parque industrial Las torres</a></li>
                        <li><a>Santa Catarina, NL</a></li>
                        <li><a>C.P 66367</a></li>
                    </ul>
                </div>
            </footer>
        </div>
    );
}
export { MainPage };