import React, { Fragment } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";

import ResizeDetector from "react-resize-detector";

import AppMain from "../../Layout/AppMain";
import CurrentUser from "../../components/CurrentUser";
import LoginBoxed from "../UserPages/LoginBoxed";

import { MainPage } from "../MainPage";
import ForgotPasswordBoxed from "../UserPages/ForgotPasswordBoxed";
import ResetPassword from "../UserPages/ResetPassword";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false,
    };
  }

  render() {
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props;

    if(this.props.location.pathname == "/"){
      return(<MainPage />);
    
    }else if(this.props.location.pathname == "/forgot-password-boxed"){
      return(<ForgotPasswordBoxed />);
    }else if(this.props.location.pathname.startsWith("/reset-password")){
      const token = this.props.location.pathname.replace("/reset-password/", "");
      return(<ResetPassword token={token}/>);
    }
    else{
      return (
        <ResizeDetector
          handleWidth
          render={({ width }) => (
            <Fragment>
              <CurrentUser>
                {currentUser => (
                  <>
                  {currentUser && (
                    <div
                      className={cx(
                        "app-container app-theme-" + colorScheme,
                        { "fixed-header": enableFixedHeader },
                        { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                        { "fixed-footer": enableFixedFooter },
                        { "closed-sidebar": enableClosedSidebar || width < 1250 },
                        {
                          "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                        },
                        { "sidebar-mobile-open": enableMobileMenu },
                        { "body-tabs-shadow-btn": enablePageTabsAlt }
                      )}>
                      <AppMain />
                    </div>
                  )}
                  {!currentUser && (
                    <LoginBoxed/>
                  )}
                  </>
                )}
              </CurrentUser>
            </Fragment>
          )}
        />
      );
    }
  }
}

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
});

export default withRouter(connect(mapStateToProp)(Main));
