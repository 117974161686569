import React from "react";
import {useGetCurrentUser} from "../hooks/User";

const CurrentUser = (props) => {
  const currentUser = useGetCurrentUser()

  if(currentUser.me){
    return(props.children(currentUser.me))
  } else {
    console.log("Plese signin first!");
    return(props.children(null))
  }
}

export default CurrentUser;
