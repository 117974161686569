import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import {createLink} from "apollo-absinthe-upload-link/src";

const API_URL = ""+window.location.protocol+"//"+window.location.hostname+":4001/api/"

const token = window.localStorage.getItem('auth-token')
const headers={authorization: token ? `Bearer ${token}` : ""}
const client = new ApolloClient({
    link: createLink({
        uri: API_URL,
        headers: headers
    }),
    cache: new InMemoryCache(),
});

export default client;
