import React, { Fragment, useState } from "react";
// Styles ...
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import Logotec from "../../../assets/images/tecnomodulare_logo_2.svg";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
// Components ...
import Error from "../../../components/Error";
// Hooks ...
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "../../../hooks/User";

const ResetPassword = ({ token }) => {
  // State for handling password visibility
  const [showPassword, setShowPassword] = useState(false);

  // State for handling errors
  const [error, setError] = useState("");

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // State for handling password inputs
  const [formResetPassword, setFormResetPassword] = useState({
    password: "",
    passwordConfirmation: "",
  });

  // Mutation to reset password
  const [resetPassword] = useMutation(RESET_PASSWORD, {
    variables: {
      token,
      password: formResetPassword.password,
      passwordConfirmation: formResetPassword.passwordConfirmation,
    },
    onError(error) {
      setError(error);
    },
    update(cache, result) {
      window.location.href = "/#/admin";
    },
  });

  // Handle input change for password fields
  const handleInput = (event) => {
    const { name, value } = event.target;
    setFormResetPassword((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Check if the form is valid
  const isFormValid = () => {
    return (
      formResetPassword.password.length > 0 &&
      formResetPassword.passwordConfirmation.length > 0
    );
  };
  return (
    <Fragment>
      <div className="h-100 bg-layout bg-animation">
        <div className="d-flex flex-col h-100 justify-content-center align-items-center">
          <img
            className="logotec"
            src={Logotec}
            alt="tecnomodulares logo cube"
          />
          <Col md="8" className="mx-auto app-login-box">
            <div className="modal-dialog w-100 mx-auto">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="h5 modal-title">
                    Restablece tu contraseña
                    <h6 className="mt-1 mb-0 opacity-8">
                      <span>
                        Ingresa tu correo electrónico, te enviaremos
                        instrucciones para restablecer tu contraseña.
                      </span>
                    </h6>
                  </div>
                </div>
                <div className="modal-body">
                  <div>
                    <Form>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            {error && <Error error={error} />}
                            <Label for="password">Contraseña</Label>
                            <div style={{ position: "relative" }}>
                              <Input
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                id="password"
                                name="password"
                                placeholder="Ingresar Contraseña"
                                onChange={handleInput}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  right: "10px",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                                onClick={togglePasswordVisibility}
                              >
                                {showPassword ? (
                                  <BsEyeFill />
                                ) : (
                                  <BsEyeSlashFill />
                                )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                    <Form>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="exampleEmail">
                              Confirmar contraseña
                            </Label>

                            <Input
                              type={showPassword ? "text" : "password"}
                              name="passwordConfirmation"
                              id="passwordConfirmation"
                              placeholder="Ingresar Email"
                              className="form-control"
                              onChange={handleInput}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
                <div className="modal-footer clearfix">
                  <div className="float-end">
                    <Button
                      color="primary"
                      size="lg"
                      onClick={resetPassword}
                      disabled={!isFormValid()}
                    >
                      Restablecimiento de contraseña
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </div>
    </Fragment>
  );
};

export default ResetPassword;
