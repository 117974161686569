import { gql, useQuery } from "@apollo/client";

export const useGetUsers = () => {
  const GET_USERS = gql`
    query GetUsers {
      getUsers {
        id
        email
        role
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_USERS);
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  if (data) return data;
};

export const useGetCurrentUser = () => {
  const GET_CURRENT_USER = gql`
    query GetCurrentUser {
      me {
        id
        email
        role
        insertedAt
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_CURRENT_USER);
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  if (data) return data;
};

export const useGetUser = (userId) => {
  const GET_USER = gql`
    query GetUser($userId: ID!) {
      getUser(userId: $userId) {
        id
        email
      }
    }
  `;
  const { loading, error, data } = useQuery(GET_USER, {
    variables: { userId },
  });
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  if (data) return data;
};

export const SIGNIN = gql`
  mutation SignIn($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      token
      user {
        id
        email
      }
    }
  }
`;

export const SIGNUP = gql`
  mutation SignUp($email: String!, $password: String!) {
    signup(email: $email, password: $password) {
      token
      user {
        id
        email
      }
    }
  }
`;

export const UPDATE_EMAIL = gql`
  mutation UpdateEmail($userId: ID!, $email: String!) {
    updateEmail(userId: $userId, email: $email) {
      id
      email
    }
  }
`;

export const REMOVE_USER = gql`
  mutation DeleteUser($userId: ID!) {
    deleteUser(userId: $userId) {
      id
      email
    }
  }
`;

export const CREATE_FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
}
`;

export const RESET_PASSWORD = gql`
    mutation ResetPassword($token: String!, $password: String!, $passwordConfirmation: String!) {
      resetPassword(token: $token, password: $password, passwordConfirmation: $passwordConfirmation)
  }
`;
